<template>
    <div class="input-with-add-button v-application">
        <v-textarea
            outlined
            dense
            name="input-7-4"
            :disabled="disabled"
            rows="1"
            no-resize
            v-model="inputValue"
            v-on:keyup.enter="addValue"
            :placeholder="placeholder"
            class="rounded-lg"
            @paste="pasteValue"
        ></v-textarea>

        <v-btn small :color="colorName" :disabled="disabled" @click="addValue">
            <strong class="text-h5">+</strong>
        </v-btn>

    </div>

</template>


<script>

export default {
    props: {
        placeholder: {type: String,default: ''},
        colorName: {type: String,default: 'primary'},
        disabled:{type:Boolean, default:false},
    },
    data(){
        return {
            inputValue:''
        }
    },
    methods:{
        addValue(){
            this.$emit('inputWithAddButtonAddValue',this.inputValue)

            this.inputValue = ''
        },
        pasteValue(){
            setTimeout(()=>{
                this.addValue()
            }, 200);
        }
    },
    components: {

    },
}
</script>

<style lang="scss" >
.input-with-add-button{
    position: relative;
    .v-text-field__details{
        display: none;
    }
    .v-text-field--outlined.v-input--is-focused fieldset{
        border: 1px solid #393939;
    }

    .v-text-field__slot{
        textarea{
            margin-top: 0 !important;
            padding-top: 2px !important;
            line-height: 36px !important;
            height: 38px !important;
        }
    }
    .v-btn{
        position: absolute;
        display: inline-block;
        vertical-align: bottom;
        height: 38px !important;
        width: 38px !important;
        min-width: 38px !important;
        top: 1px;
        right: 1px;
        padding: 0 0 !important;

        border-radius: 0 7px 7px 0;

    }
}

</style>
