<template>
    <div v-if="displayAlert" class="mb-6">
        <vs-alert :color="switchAlertColor">
            <div id="subscription-expiration-alert">
                <p>
                    <strong>
                        {{ $i18n.t(`component_subscriptionExpiration_alert_title_${this.expirationType}`) }}
                        <a :href="purchasePlanUrl" target="_blank" v-if="productFamily === 'yooda-one'">
                            {{ $i18n.t(`component_subscriptionExpiration_alert_renewLink_${this.expirationType}`) }}
                        </a>.
                    </strong>
                </p>
                <div v-if="this.expirationType !== 'grace_period' && this.expirationType !== 'inactive'">
                    <p>{{ $i18n.t(`component_subscriptionExpiration_alert_project_update_${this.expirationType}`) }}</p>
                    <p>{{ displayConservationTimeTitle }}</p>
                </div>
            </div>
        </vs-alert>


        <vs-popup title="" :active.sync="displayPopin">
            <div id="popin-expiration-info" class="text-center" :class="expirationType">
                <h4>{{ $i18n.t(`component_subscriptionExpiration_popin_title_${this.expirationType}`) }}</h4>
                <p class="my-4">{{ $i18n.t(`component_subscriptionExpiration_popin_subtitle_${this.expirationType}`) }}</p>
                <div v-html="$i18n.t('component_subscriptionExpiration_popin_list_option')"></div>
                <span class="mt-6" v-if="this.expirationType !== 'inactive'">{{ displayConservationTimeText }}</span>
                <div class="vx-row" v-if="productFamily === 'yooda-one'">
                    <div class="vx-col w-full mt-10 md:w-6/12 lg:w-6/12 xl:w-6/12" style="text-align: right">
                        <vs-button color="primary" type="border" @click="displayPopin=false">
                            {{ $i18n.t(`component_subscriptionExpiration_popin_ignore_button_${this.expirationType}`) }}
                        </vs-button>
                    </div>
                    <div class="vx-col w-full mt-10 md:w-6/12 lg:w-6/12 xl:w-6/12" style="text-align: left">
                        <vs-button color="success" type="filled" target :href="(this.expirationType === 'grace_period' ? restoreSubscriptionUrl : purchasePlanUrl )">
                            {{ $i18n.t(`component_subscriptionExpiration_popin_renew_button_${this.expirationType}`) }}
                        </vs-button>
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>


</template>


<script>

import {mapGetters} from "vuex";

export default {
    props:{
        withPopin:{
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expirationType:'subscription',
            displayTrialing:false,
            displayAlert:false,
            displayPopin:false
        }
    },
    computed: {
        ...mapGetters({
            activeSubscription:"customersManager/getActiveSubscription"
        }),

        restoreSubscriptionUrl(){
            return new URL(`${process.env.VUE_APP_CUSTOMERS_MANAGER_FRONTEND_BASE_URI}/request-subscription-restore?subscription-id=${this.activeSubscription().id}`).href
        },
        purchasePlanUrl(){
            return new URL(`${process.env.VUE_APP_CUSTOMERS_MANAGER_FRONTEND_BASE_URI}/purchase/select-plan?subscription-id=${this.activeSubscription().id}`).href
        },
        displayConservationTimeTitle(){
            return this.$i18n.t(`component_subscriptionExpiration_alert_subtitle_${this.expirationType}`).replace('[*DAY-COUNT*]', this.getConservationTime())
        },
        displayConservationTimeText(){
            return this.$i18n.t(`component_subscriptionExpiration_popin_conservation_time_${this.expirationType}`).replace('[*DAY-COUNT*]', this.getConservationTime())
        },
        switchAlertColor() {
            if (this.expirationType !== 'grace_period' && this.expirationType !== 'inactive') {
                return 'danger'
            }

            return 'success'
        },
        productFamily(){
            return process.env.VUE_APP_PRODUCT_FAMILY_HANDLE
        },
    },
    methods:{
        getConservationTime(){
            let dayRemaining = 0
            if(this.activeSubscription().referenceDate.getTime() && this.activeSubscription().yoodaParameters.stackRunningGracePeriod){
                dayRemaining = Math.max(
                    Math.floor(this.activeSubscription().yoodaParameters.stackRunningGracePeriod
                    - ((new Date().getTime() -  this.activeSubscription().referenceDate.getTime()) / (1000 * 3600 * 24))),
                    0
                )
            }
            if(this.activeSubscription().gracePeriodEnded){
                if (this.activeSubscription().referenceDate.getTime() && this.activeSubscription().yoodaParameters.backupConservationGracePeriod) {
                    dayRemaining = Math.max(
                        Math.floor(this.activeSubscription().yoodaParameters.backupConservationGracePeriod
                            - ((new Date().getTime() -  this.activeSubscription().referenceDate.getTime()) / (1000 * 3600 * 24))),
                        0
                    )
                }

                if(dayRemaining <=0){
                    this.expirationType = 'inactive'
                }
            }


            return dayRemaining
        },
    },
    mounted() {

        if(this.activeSubscription().state !== 'active' && this.activeSubscription().state !== 'trialing'){

            this.displayAlert = true
            this.expirationType = (this.activeSubscription().state === 'trial_ended' ? 'trial' : 'subscription')

            if(this.activeSubscription().gracePeriodEnded){
                this.expirationType = 'grace_period'
            }

            this.displayPopin = this.withPopin
        }

        if(this.activeSubscription().state === 'trialing'){
            this.displayTrialing = true
        }


    }
}
</script>

<style lang="scss">
    .vs-con-alert {
        height: auto;
    }
    #subscription-expiration-alert{
     text-align: center;
        >span{
            font-size: 12px;
        }
    }
    #popin-expiration-info{
        >h4{
            color: $danger;
        }
        ul{
            text-align: left;
            margin: auto;
            width: 320px;

            li{
                &:before{
                    content: '\6e';
                    font-family: yoodafont;
                    color: $success;
                    margin-right: 8px;
                }
            }
        }
        >span{
            display: block;
            &:before{
                content: '\4d';
                font-family: yoodafont;
                margin-right: 8px;
            }
        }
        &.inactive, &.grace_period{
            h4{
                color: $success;
            }
        }
    }

</style>
