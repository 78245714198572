<template>
    <div id="page-placeholder-component">
        <div v-if="!isMyProject">
            <not-owned-placeholder/>
        </div>
        <div v-else-if="initialisationComplete && project && project.data.projectLoaded">

            <subscription-expiration/>

            <div v-if="projectCreationWaiting">
                <project-creation-waiting-placeholder/>
                <onboarding-waiting-compute/>
            </div>
            <vs-alert :active="getProjectError" color="warning" icon="new_releases" class="mb-10" :title="$t('common_errors_report_dataTitle')" v-else>
                <div v-html="$t('common_errors_report_dataText')"></div>
            </vs-alert>

            <div class="v-application">
                <h4 class="text-h4 font-weight-black primary--text mb-12 mt-6">
                    {{ this.pageName }}

                    <v-menu bottom :offset-y="true" v-if="$route.name === 'dashboard' || $route.name === 'dashboard_recommendations'">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" style="border-bottom: 1px dashed">
                                {{ $route.name === 'dashboard' ? $t('page_link_name_dashboard_switch_evolution') : $t('page_link_name_dashboard_switch_recommendations') }}
                                <v-icon color="primary">mdi-chevron-down</v-icon>
                            </span>

                        </template>
                        <v-list>
                            <v-list-item>
                                <router-link :to="{name:'dashboard'}">{{ `${$t('page_link_name_dashboard')} ${$t('page_link_name_dashboard_switch_evolution')}` }}</router-link>
                            </v-list-item>
                            <v-list-item>
                                <router-link :to="{name:'dashboard_recommendations'}">{{ `${$t('page_link_name_dashboard_recommendations')} ${$t('page_link_name_dashboard_switch_recommendations')}` }}</router-link>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <strong class="subtitle-1 ml-2 grey--text text--darken-1">({{ $t('common_report_date_title') }} {{ lastReportDate }})</strong>

                </h4>
            </div>

            <slot></slot>
        </div>
        <div v-else-if="project.error">
            <error-placeholder/>
        </div>
        <div v-else>
            <component-loader/>
        </div>
    </div>

</template>


<script>

import {mapGetters} from "vuex";
import apiCalls from "@/api/axios/configuration/project";
import SubscriptionExpiration from "@/components/yooda-components/SubscriptionExpiration";
import NotOwnedPlaceholder from "@/components/yooda-components/placeholder/NotOwnedPlaceholder";
import ErrorPlaceholder from "@/components/yooda-components/placeholder/ErrorPlaceholder";
import ProjectCreationWaitingPlaceholder from "@/components/yooda-components/placeholder/ProjectCreationWaitingPlaceholder";
import OnboardingWaitingCompute from "@/components/yooda-components/onboarding-helper/OnboardingWaitingCompute.vue";
export default {
    name: 'PagePlaceholder',
    data() {
        return {
            projectList:null,
            initialisationComplete:false
        }
    },
    computed: {
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",
            getActiveSubscription:"customersManager/getActiveSubscription",
        }),


        lastReportDate(){
            let lastDate = ''
            if(this.projectReports && this.projectReports.length >0){
                this.projectReports.forEach(data=>{
                    if(data.complete && lastDate === ''){
                        lastDate = new Date(data.createdAt).toLocaleDateString('fr-FR')
                    }
                })
            }

            return lastDate
        },

        pageName(){
            return this.$t(`navigationMenu_link_title_${this.$route.name}`)
        },
        isMyProject(){
            let mySubscription = false
            let myProject = true

            if(this.getActiveSubscription().id === parseInt(this.$route.params.subscriptionId)){
                mySubscription = true
            }
            if(this.projectList.data){
                myProject = false
                this.projectList.data.forEach(project=>{
                    if(project.id === parseInt(this.$route.params.projectId)){
                        myProject = true
                    }
                })
            }

            return (myProject && mySubscription)
        },

    },

    components: {
        SubscriptionExpiration,
        NotOwnedPlaceholder,
        ErrorPlaceholder,
        ProjectCreationWaitingPlaceholder,
        OnboardingWaitingCompute,
    },
    beforeMount() {
        this.setProjectError(false)


        this.getComponentData({
            componentName:'projectList',
            promise: apiCalls.getProjects(this.$route.params.subscriptionId, false)
        }).then(()=>{
            this.initialisationComplete = true
            this.analyticsIdentify()
        })


    }
}
</script>

