<template>
    <div class="v-application">
        <v-btn
            outlined
            color="primary"
            @click="showImportKeywordContainer=true"
        >
            {{ $t('component_importCsvKeyword_importButton') }}
        </v-btn>

        <vs-popup :title="$t('component_importCsvKeyword_popinTitle')" :active.sync="showImportKeywordContainer" id="importKeywordPopin">
                  <p class="mb-6">{{ $t('component_importCsvKeyword_popinSubtitle') }}</p>
            <div>
                <vs-tabs v-model="selectedTab">
                    <vs-tab :label="$t('component_importCsvKeyword_popinTabCopyPaste')">
                        <div class="copy-paste-container">
                            <p>{{ $t('component_importCsvKeyword_popinTabCopyPasteTitle') }}</p>
                            <textarea
                                v-model="pastedData"
                                :placeholder="$t('component_importCsvKeyword_popinTabCopyPastePlaceholder')">
                            </textarea>
                        </div>
                    </vs-tab>

                    <vs-tab :label="$t('component_importCsvKeyword_popinTabFile')">
                        <information-tooltip
                            :title="$t('component_importCsvKeyword_informationTooltip_title')"
                            :text="$t('component_importCsvKeyword_informationTooltip_text')"
                            :tooltip-width="300"
                        ></information-tooltip>

                        <div class="import-file-container" style="text-align: center">
                            <div v-if="csvImportContent">
                                <p>{{ $t('component_importCsvKeyword_popinTabFileTitlePreview') }}</p>

                                <div class="csv-import-display-content">{{csvImportContent}}</div>
                                <vs-button color="primary" type="border" size="small" @click="importCsvFile">{{ $t('component_importCsvKeyword_popinTabFileButtonChangeFile') }}</vs-button>
                            </div>
                            <div v-else>
                                <p>{{ $t('component_importCsvKeyword_popinTabFileTitleImport') }}</p>
                                <yooda-icon name="cloud-upload" :size="70" style="display: block; margin: 32px 0; opacity: 0.7"></yooda-icon>
                                <vs-button color="primary" type="border" size="small" @click="importCsvFile">{{ $t('component_importCsvKeyword_popinTabFileButtonImportFile') }}</vs-button>
                            </div>

                            <input type="file" @change="parseCsvFile" ref="importCsvButton" style="display: none"/>
                        </div>
                    </vs-tab>
                </vs-tabs>


                <div class="vx-row mt-4">

                    <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-0" style="text-align: center">
                        <small-error-message ref="keywordsErrorMessage"></small-error-message>
                    </div>
                    <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-0" style="text-align: right">
                        <vs-button color="primary" @click="importCsvData">{{ $t('component_importCsvKeyword_popinSave') }}</vs-button>
                    </div>
                </div>
            </div>

        </vs-popup>
    </div>


</template>


<script>

import SmallErrorMessage from "@/components/yooda-components/SmallErrorMessage";
import InformationTooltip from "@/components/yooda-components/InformationTooltip";
import apiCalls from "@/api/axios/configuration/project";
import {fileUtils} from "@/mixins/fileUtils";

export default {
    props:{
        saveMode:{
            type:Boolean,
            default:true
        }
    },

    data() {
        return {
            showImportKeywordContainer:false,
            selectedTab:0,
            pastedData:null,
            csvImportContent:null,
        }
    },
    components: {
        SmallErrorMessage,
        InformationTooltip
    },
    computed:{

    },
    methods:{
        importCsvFile(){
            this.$refs.importCsvButton.click()
        },
        async parseCsvFile(event){

            const DEFAULT_CHARSET = 'UTF-8'

            const file = event.target.files[0]

            // protect against aborted file open
            if (!(file instanceof File)) {
                return
            }

            const self = this
            const buffer = await this.readAsBinaryString(file)
            const encoding = this.guessEncoding(buffer)
            let string;

            if (encoding !== DEFAULT_CHARSET) {
                string = this.convertEncoding(encoding, DEFAULT_CHARSET, buffer).toString()
            } else {
                string = await this.readAsText(file, DEFAULT_CHARSET)
            }

            this.$papa.parse(string, {
                header: false,
                skipEmptyLines:true,
                encoding: DEFAULT_CHARSET,
                complete: function (results) {

                    self.csvImportContent = '';
                    if(results.errors.length>0 && results.errors[0].code !== 'UndetectableDelimiter'){
                        self.$refs.keywordsErrorMessage.displayErrorMessage('keywords_malformattedCsv')
                    }
                    else{
                        if(results.data.length > 0){
                            results.data.forEach(row=>{
                                if(row.length === 1){
                                    row = row[0].split(';')
                                }
                                self.csvImportContent += self.cleanName(row[0])+";"+self.cleanName((row[1] ? row[1] : ''))+"\r\n"
                            })
                        }
                        else{
                            self.$refs.keywordsErrorMessage.displayErrorMessage('keywords_malformattedCsv')
                        }
                    }
                }
            });
        },

        importCsvData(){
            let self = this
            let importedContent = []
            let csvData = null
            if(this.selectedTab === 0){
                csvData = this.pastedData
            }
            else{
                csvData = this.csvImportContent
            }

            if(csvData){

                let parsedData = this.$papa.parse(csvData,{delimiter:";", skipEmptyLines:true})

                if(parsedData.errors.length>0){
                    self.$refs.keywordsErrorMessage.displayErrorMessage('keywords_malformattedCsv')
                }
                else{
                    parsedData.data.forEach(data=>{
                        if(data[0]){
                            importedContent.push({
                                keyword:self.cleanName(data[0]),
                                segment:self.cleanName((data[1] ? data[1] : ''))
                            })
                        }

                    })

                    this.saveImportedCsv(importedContent)
                }

                this.pastedData = ''
                this.csvImportContent = ''
            }
        },

        async saveImportedCsv(csvData){
            let newKeywords = []
            let newSegments = []
            let tmpAddedKeyword = []
            let tmpAddedSegment = []

            this.showImportKeywordContainer = false

            csvData.forEach(data=>{

                let keywordAlreadyExist = false

                this.projectKeywords.data.forEach(keyword => {
                    if(data.keyword.toLowerCase() === keyword.keyword.toLowerCase()){
                        keywordAlreadyExist = true
                    }
                })
                if(!keywordAlreadyExist){
                    if(data.keyword.trim() !== ""){
                        if(!tmpAddedKeyword[data.keyword]){
                            newKeywords.push({string:data.keyword})
                            tmpAddedKeyword[data.keyword] = true
                        }
                    }
                }

                let segmentAlreadyExist = false
                this.projectSegments.data.forEach(segment => {
                    if(data.segment.toLowerCase() === segment.name.toLowerCase()){
                        segmentAlreadyExist = true
                    }
                })
                if(!segmentAlreadyExist){
                    if(data.segment.trim() !== ""){
                        if(!tmpAddedSegment[data.segment]){
                            newSegments.push({name:data.segment})
                            tmpAddedSegment[data.segment] = true
                        }
                    }
                }
            })

            if(this.saveMode){
                this.$vs.loading()
                if(newKeywords.length>0){ await this.createKeyword(newKeywords) }

                if(newSegments.length >0){ await this.createSegment(newSegments) }

                await this.addSegmentToKeyword(csvData)

                this.$vs.loading.close()
                this.$vs.notify({
                    time:3000,
                    title:'SUCCES ',
                    text:this.$t('common_keyword_imported'),
                    position:'top-right',
                    color:'success'
                })
            }
            else{
                this.$emit('csvImported',{
                    keywords:newKeywords,
                    segments:newSegments,
                    csvData:csvData
                })

            }


        },

        createKeyword(newKeywords){
            let cleanedKeywords = []
            newKeywords.forEach((keyword)=>{
                let cleanKeyword = this.cleanKeywordInput(keyword.string)
                if(cleanKeyword.trim() !== ''){
                    cleanedKeywords.push({string:cleanKeyword})
                }
            })
            if(cleanedKeywords.length >0){
                let promise = apiCalls.createKeyword(this.$route.params.projectId, {keywords:cleanedKeywords})
                return promise.then((response)=>{
                    if(response){
                        return this.setDataComponent( {
                            stateName:'projectKeywords',
                            promise: apiCalls.getKeywords(this.$route.params.projectId),
                            dataFormatter: this.projectKeywordsData
                        })
                    }
                }).catch((error)=>{
                    this.displayForbidenError(error.response)
                    this.$vs.loading.close()
                })
            }

        },


        createSegment(newSegments){
            let promise = apiCalls.createKeywordGroup(this.$route.params.projectId, {keywordGroups:newSegments})
            return promise.then((response)=>{
                if(response){
                    return this.setDataComponent( {
                        stateName:'projectSegments',
                        promise: apiCalls.getKeywordGroups(this.$route.params.projectId),
                        dataFormatter: this.$route.params.projectId ?  this.noDataFormat : this.formatData
                    })
                }
            }).catch((error)=>{
                this.displayForbidenError(error.response)
                this.$vs.loading.close()
            })
        },

        addSegmentToKeyword(csvData){
            let marketKeywords = []
            let segments = []
            csvData.forEach((data)=>{
                if(data.segment.trim() !== ''){
                    if(!marketKeywords[data.segment]){
                        let segment = this.getSegmentByName(data.segment)
                        if(segment){ marketKeywords[data.segment] = {segmentId:segment.id, keywords:[]}}
                        segments.push(data.segment)
                    }
                    if(marketKeywords[data.segment]){
                        let keyword = this.getKeywordByName(data.keyword)
                        if(keyword){marketKeywords[data.segment].keywords.push({id:keyword.keywordId})}

                    }
                }
            })

            let count = 0
            segments.forEach(segmentName=>{
                let promise = apiCalls.addKeywordsToKeywordGroup(marketKeywords[segmentName].segmentId,{keywords:marketKeywords[segmentName].keywords})
                promise.then((response)=>{
                    count ++
                    if(response){
                        if(count >= segments.length){
                            this.setDataComponent( {
                                stateName:'projectKeywords',
                                promise: apiCalls.getKeywords(this.$route.params.projectId),
                                dataFormatter :this.projectKeywordsData
                            })

                            this.setDataComponent( {
                                stateName:'projectSegments',
                                promise: apiCalls.getKeywordGroups(this.$route.params.projectId),
                                dataFormatter: this.$route.params.projectId ?  this.noDataFormat : this.formatData
                            })
                        }
                    }
                    this.$vs.loading.close()
                }).catch((error)=>{
                    this.displayForbidenError(error.response)
                    this.$vs.loading.close()
                })
            })


            this.setDataComponent({
                stateName:'projectInfo',
                promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                dataFormatter: this.projectData
            }).then(()=>{
                this.setDataComponent({
                    stateName:'projectsSummary',
                    promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                })
            })

        },

        getSegmentByName(name){
            let findedSegment = this.projectSegments.data.filter(segment=>{
                return segment.name.toLowerCase().trim() === name.toLowerCase().trim()
            })
            return (findedSegment.length>0 ? findedSegment[0] : null)
        },
        getKeywordByName(name){
            let findedKeyword = this.projectKeywords.data.filter(keyword=>{
                return keyword.keyword.toLowerCase().trim() === name.toLowerCase().trim()
            })
            return (findedKeyword.length>0 ? findedKeyword[0] : null)
        },
        cleanName(string){
            const regex = /["([|`_^)\]}{!\\/;,?¤<\t\r\n]/gmi
            return string.replace(regex,'')
        },

        formatData(){
            return []
        }
    },

    beforeMount() {

        this.setDataComponent( {
            stateName:'projectSegments',
            promise: this.$route.params.projectId ? apiCalls.getKeywordGroups(this.$route.params.projectId) : null,
            dataFormatter: this.$route.params.projectId ?  this.noDataFormat : this.formatData
        })

        this.setDataComponent({
            stateName:'projectKeywords',
            promise: this.$route.params.projectId ? apiCalls.getKeywords(this.$route.params.projectId) : null,
            dataFormatter: this.$route.params.projectId ?  this.projectKeywordsData : this.formatData
        })


    },
    mixins: [fileUtils]
}
</script>

<style lang="scss">

#importKeywordPopin{
    .vs-popup,.vs-popup--header, .vs-popup--content{
        background-color: #f6f6f6 !important;
    }
    .vs-popup{
        width: 700px !important;
        .vs-popup--content{
            >div{
                padding: 0 20px;
            }
            .vs-tabs{
                .vs-tabs--li{
                    background-color: #c1c1c1;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    button{
                        color: white !important;
                        span{
                            font-size: 16px !important;
                        }
                    }
                    &.activeChild{
                        background-color: $primary;
                    }
                }
                .vs-tabs--content{
                    background-color: white;
                    border: 1px solid #c1c1c1;
                    height: 215px;
                    .copy-paste-container, .import-file-container{
                        >p{
                            font-size: 12px;
                            text-align: center;
                            margin: 6px 0 12px 0;
                        }
                        >textarea{
                            width: 100%;
                            height: 150px;
                            resize: none;
                            border: none;
                        }
                    }
                    .vbtn-for-yooda-icon{
                        position: absolute;
                        right: 0;
                    }
                    .import-file-container{
                        .csv-import-display-content{
                            overflow: auto;
                            height: 135px;
                            padding: 5px 0;
                            text-align: left;
                            font-size: 12px;
                            white-space: pre-wrap;
                        }
                    }
                }
                .line-vs-tabs{
                    display: none;
                }
            }
        }
    }
}


</style>
