<template>
    <div>

        <component-container
            :card-title="$t('component_marketSegmentConfiguration_title')"
            :card-subtitle="$t('component_marketSegmentConfiguration_subtitle')"

            :height="760"

            :information-tooltip="{
                title: $t('component_marketSegmentConfiguration_informationTooltip_title'),
                text: $t('component_marketSegmentConfiguration_informationTooltip_text')
            }"

            :component-data="{response:{isLoaded:componentLoaded}, hasData:true}"
        >
            <template v-slot:content>
                <div class="mb-6">
                    <input-with-add-button
                        :placeholder="$t('component_marketSegmentConfiguration_addLabel')"
                        color-name="#cea2df"
                        @inputWithAddButtonAddValue="addSegments"
                    />
                    <small-error-message ref="marketSegmentErrorMessage"></small-error-message>
                </div>

                <div>
                    <vs-checkbox class="segment-color-checkbox" v-model="selectAllSegment">{{ $t('component_marketSegmentConfiguration_selectAll') }}</vs-checkbox>
                </div>


                <div id="marketSegmentList" v-if="projectSegments && projectSegments.data">

                    <vs-collapse accordion>
                        <vs-collapse-item v-for="(segment,index) in projectSegments.data" :key="index">
                            <div slot="header">
                                <vs-checkbox v-model="segmentListId[segment.id]" class="segment-color-checkbox"></vs-checkbox>
                                {{ segment.name }} ({{segment.keywords.length}})
                            </div>

                            <div class="pb-8">
                                <vs-chip
                                    v-for="(keyword, index) in segment.keywords"
                                    :key="index"
                                    closable
                                    class="chips-keyword"
                                    @click="removeKeywordsFromSegment(segment.id,[{id:keyword.id}])"
                                    :color="getThemeColorByName('marketSegmentColor2')"
                                >
                                    {{keyword.string}}
                                </vs-chip>
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </div>
                <component-loader v-else height="380px"></component-loader>

                <vs-button
                    type="border"
                    :disabled="disableActionButton"
                    @click="confirmClearSegment"
                    style="margin-top: 12px;width: 100%"
                >
                    {{ $t('component_marketSegmentConfiguration_removeAllKeywordFromSegment') }}
                </vs-button>

                <vs-button
                    color="danger"
                    type="border"
                    :disabled="disableActionButton"
                    @click="confirmDeleteSegment"
                    style="margin-top: 12px;width: 100%"
                >
                    {{ $t('component_marketSegmentConfiguration_removeSegment') }}
                </vs-button>
            </template>
        </component-container>


        <confirm-dialog :title="$t('component_marketSegmentConfiguration_confirmDeleteTitle')" ref="confirmDeleteSegment" @confirm-dialog-valid="acceptDeleteSegment()">
            <div v-html="$t('component_marketSegmentConfiguration_confirmDeleteText')"></div>
        </confirm-dialog>
        <confirm-dialog :title="$t('component_marketSegmentConfiguration_confirmClearTitle')" ref="confirmClearSegment" @confirm-dialog-valid="acceptClearSegment()">
            <div v-html="$t('component_marketSegmentConfiguration_confirmClearText')"></div>
        </confirm-dialog>

    </div>

</template>


<script>


import apiCalls from '@/api/axios/configuration/project.js'
import SmallErrorMessage from "@/components/yooda-components/SmallErrorMessage";
import InputWithAddButton from "@/components/yooda-components/form/InputWithAddButton";
import Vue from "vue"

export default {

    data() {
        return {
            segmentListId:[],
            componentLoaded: false,
            selectAllSegment: false,
            segmentErrorMessage:''
        }
    },
    components:{
        SmallErrorMessage,
        InputWithAddButton
    },
    computed:{

        disableActionButton(){
            if(this.getSelectedSegmentCount() === 0){
                return true
            }
            return false
        }
    },

    watch:{
        selectAllSegment(){
            if(this.selectAllSegment){
                this.segmentListId = []
                this.projectSegments.data.forEach((segment)=>{
                    this.segmentListId[segment.id] = true
                })
            }else{
                if(this.getSelectedSegmentCount() === this.projectSegments.data.length){
                    this.segmentListId = []
                }
            }
        },

        segmentListId(){
            if(this.getSelectedSegmentCount() === this.projectSegments.data.length){
                this.selectAllSegment = true
            }
            else{
                this.selectAllSegment = false
            }
        }
    },

    methods:{
        addSegments(inputSegment){
            /* eslint-disable */
            const regex = /\?|!|_|`|\^|\[|\]|"|\(|\)|\||\{|\}|,|;|¤|\<|\>|\t|\r|\n/gi;
            /* eslint-enable */


            let newSegments = []
            let hasError = false
            this.$refs.marketSegmentErrorMessage.hideErrorMessage()


            if(this.projectSegments.data.length>=50){
                this.$refs.marketSegmentErrorMessage.displayErrorMessage('marketSegment_maxCount')
                hasError = true
            }
            else{
                this.explodeStringToArray(inputSegment).forEach((segment)=>{
                    segment = segment.replace(regex, '')

                    if(segment.trim().length >30){
                        this.$refs.marketSegmentErrorMessage.displayErrorMessage('marketSegment_nameTooLong')
                        hasError = true
                    }
                    else{
                        if(segment.trim().length >0){
                            if(!this.checkSegmentNameAlreadyExist(segment)){
                                newSegments.push({name:segment})
                            }
                            else{
                                this.$refs.marketSegmentErrorMessage.displayErrorMessage('marketSegment_alreadyExist')
                                hasError = true
                            }
                        }

                    }
                })
            }

            if(!hasError){
                if(newSegments.length>0){
                    this.$vs.loading()
                    let promise = apiCalls.createKeywordGroup(this.$route.params.projectId, {keywordGroups:newSegments})
                    promise.then((response)=>{
                        if(response){
                            this.setDataComponent( {
                                stateName:'projectSegments',
                                promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                            })

                            this.setDataComponent( {
                                stateName:'projectInfo',
                                promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                                dataFormatter: this.projectData
                            })

                            this.analyticsIdentify()
                        }

                        localStorage.setItem('settingMarketDone','true')

                        this.$vs.loading.close()
                    }).catch((error)=>{
                        this.displayForbidenError(error.response)
                        this.$vs.loading.close()
                    })
                }
            }
        },

        confirmDeleteSegment(){
            this.$refs.confirmDeleteSegment.displayDialog()
        },

        acceptDeleteSegment(){
            let selectedSegment = []
            this.segmentListId.forEach((isSelected, segmentId)=>{
                if(isSelected){
                    selectedSegment.push({id:segmentId})
                }
            })

            if(selectedSegment.length>0){
                let promise = apiCalls.deleteKeywordGroups(this.$route.params.projectId, {keywordGroups:selectedSegment})
                promise.then((response)=>{
                    if(response){
                        this.setDataComponent( {
                            stateName:'projectKeywords',
                            promise: apiCalls.getKeywords(this.$route.params.projectId),
                            dataFormatter: this.projectKeywordsData
                        })

                        this.setDataComponent( {
                            stateName:'projectSegments',
                            promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                        })
                        this.setDataComponent( {
                            stateName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        })
                        this.analyticsIdentify()

                    }
                }).catch((error)=>{
                    Vue.error('delete keywordGroups',error)
                })
            }
        },

        confirmClearSegment(){
            this.$refs.confirmClearSegment.displayDialog()
        },

        acceptClearSegment(){
            this.segmentListId.forEach((isSelected, segmentId)=>{
                if(isSelected){
                    let keywords = this.getKeywordsFromSegmentId(segmentId)
                    if(keywords.length >0){
                        this.removeKeywordsFromSegment(segmentId, keywords)
                    }
                }
            })

        },

        removeKeywordsFromSegment(segmentId, keywords){

            let promise = apiCalls.removeKeywordsFromKeywordGroup(segmentId, {keywords:keywords})
            promise.then((response)=>{
                if(response){
                    this.setDataComponent( {
                        stateName:'projectKeywords',
                        promise: apiCalls.getKeywords(this.$route.params.projectId),
                        dataFormatter: this.projectKeywordsData
                    })
                    this.setDataComponent( {
                        stateName:'projectSegments',
                        promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                    })

                    this.analyticsIdentify()
                }
            }).catch((error)=>{
                Vue.error('delete keywordGroups',error)
            })

        },

        getSelectedSegmentCount(){
            let selectedCount = 0
            this.segmentListId.forEach((isSelected)=>{
                if(isSelected){
                    selectedCount ++
                }
            })

            return selectedCount
        },

        getKeywordsFromSegmentId(segmentId){
            let keywords = []
            this.projectSegments.data.forEach(segment=>{
                if(segment.id === segmentId){
                    keywords = segment.keywords
                }
            })
            return keywords
        },

        checkSegmentNameAlreadyExist(segmentName){
            let alreadyExist = false
            this.projectSegments.data.forEach(segment=>{
                if(segment.name.trim().toLowerCase() === segmentName.trim().toLowerCase()){
                    alreadyExist = true
                }
            })

            return alreadyExist
        },
        initComponentData(){
            this.setDataComponent( {
                stateName:'projectSegments',
                promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
            }).then(()=>{
                // if(!this.projectSegments){
                //     this.projectSegments = []
                // }
                this.componentLoaded = true
            })
        }

    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">



#marketSegmentList{
    max-height: 430px;
    overflow: auto;
    .segment-color-checkbox{

        display: inline-block;
        >span{
            display: inline-block;
            vertical-align: middle;
        }
    }
}


</style>
