<template>
    <div>
        <v-row>
            <v-col cols="12" class="v-application">
                <v-alert
                    text
                    dense
                    outlined
                    color="error"
                    icon="mdi-alert-circle-outline"
                    border="left"
                    class="bordered-alert"
                >
                    <div class="pl-2" v-html="$t('common_project_loading_error_text')"></div>
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>


<script>


export default {

}
</script>

<style lang="scss">

</style>
