import {detect} from "jschardet"
import {convert} from "encoding";


export const fileUtils = {

    methods: {

        async readAsBinaryString(file) {
            return await new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.readAsBinaryString(file)
            });
        },
        async readAsText(file, encoding) {
            return await new Promise((resolve) => {
                const reader = new FileReader()
                reader.onload = () => resolve(reader.result)
                reader.readAsText(file, encoding)
            });
        },
        guessEncoding(buffer) {
            // threshold to 0 to be sure to get an encoding even if its bullshit
            return detect(buffer, { minimumThreshold: 0 }).encoding

        },
        convertEncoding(from, to, buffer) {
            return convert(buffer, to, from)
        }
    },

}
