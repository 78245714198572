<template>
    <div id="market-segment-configuration-page">
        <div class="vx-row">
            <div class="vx-col w-full md:w-5/12 lg:w-5/12 xl:w-5/12 mb-base" >
                <market-segment-configuration></market-segment-configuration>
            </div>
            <div class="vx-col w-full md:w-7/12 lg:w-7/12 xl:w-7/12 mb-base" >
                <keyword-configuration :with-create-keyword="false" tooltip-text="component_keywordConfiguration_informationTooltip_marketSegment_text"></keyword-configuration>
            </div>
        </div>
    </div>
</template>


<script>
import PagePlaceholder from "@/components/yooda-components/PagePlaceholder";
import KeywordConfiguration from "@/components/yooda-components/project/KeywordConfiguration";
import MarketSegmentConfiguration from "@/components/yooda-components/project/MarketSegmentConfiguration";

export default {
    components: {
        PagePlaceholder,
        KeywordConfiguration,
        MarketSegmentConfiguration,
    },
    mounted() {
        this.analyticsIdentify()
    }
}

</script>
