<template>
    <div>
        <component-container
            :card-title="$t('component_keywordConfiguration_title')"
            :card-subtitle="$t('component_keywordConfiguration_subtitle')"

            :height="760"

            :information-tooltip="{
                title: $t('component_keywordConfiguration_informationTooltip_title'),
                text: $t(tooltipText)
            }"

            :component-data="{response:{isLoaded:componentLoaded}, hasData:true}"
        >

            <template v-slot:content>
                <div id="keywordAddContainer" v-if="withCreateKeyword">
                    <v-row>
                        <v-col :cols="7">
                            <input-with-add-button :placeholder="$t('component_keywordConfiguration_addPlaceholder')" @inputWithAddButtonAddValue="addKeywords"/>
                        </v-col>
                        <v-col :cols="1" class="text-center">
                            <span style="display: block; margin-top: 10px">ou</span>
                        </v-col>
                        <v-col :cols="4" class="text-right">
                            <import-csv-keyword style="display:inline-block;vertical-align: middle; margin: 0 0 0 12px !important"></import-csv-keyword>
                        </v-col>
                    </v-row>


                </div>


                <div id="keywordsList">
                    <v-row>
                        <v-col cols="4" class="v-application">
                            <v-btn
                                v-if="withCreateKeyword"
                                outlined
                                color="error"
                                @click="confirmDeleteKeyword"
                                :disabled="selected.length === 0"
                            >
                                <v-icon left >mdi-delete</v-icon>
                                {{ $t('component_keywordConfiguration_deleteKeywords') }}
                            </v-btn>

                            <div v-if="!withCreateKeyword">
                                <v-menu
                                    offset-y
                                    v-if="!disableActionButton"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            color="primary"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon left >mdi-plus-circle-outline</v-icon>
                                            {{ $t('component_keywordConfiguration_addSegmentToKeywordsLabel') }}
                                        </v-btn>
                                    </template>
                                    <v-list id="action-add-to-segment-list">
                                        <v-subheader> {{ $t('component_keywordConfiguration_addSegmentToKeywordsLabel') }}</v-subheader>
                                        <v-list-item
                                            v-for="(item, index) in projectSegments.data"
                                            :key="index"
                                            @click="addKeywordToSegment(item.id)"
                                        >
                                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-btn
                                    disabled
                                    @click="alertNoKeywordSelected"
                                    v-else
                                >
                                    <v-icon left >mdi-plus-circle-outline</v-icon>
                                    {{ $t('component_keywordConfiguration_addSegmentToKeywordsLabel') }}
                                </v-btn>
                            </div>

                        </v-col>

                        <v-col cols="4" class="v-application">
                            <v-text-field
                                v-model="searchKeyword"

                                @click:clear="resetSearchFilter"
                                clear-icon="mdi-close-circle"
                                prepend-inner-icon="mdi-magnify"
                                outlined
                                dense
                                hide-details
                                clearable
                                :label="$t('component_keywordConfiguration_filterKeywordLabel')"
                                type="text"
                            />
                        </v-col>

                        <v-col cols="4" class="v-application">
                            <v-select
                                v-model="selectedFilterSegment"
                                :items="getFilterSegments"
                                item-text="name"
                                item-value="id"
                                :label="$t('component_keywordConfiguration_filterSegmentLabel')"
                                outlined
                                dense
                                hide-details
                            ></v-select>

                        </v-col>
                    </v-row>


                    <div class="tableContainer" :class="(withCreateKeyword)?'with-create-keyword':''" v-if="filteredKeywordList">
                        <ag-grid-table
                            :show-floating-filter="false"
                            :withTableMenu="false"
                            :table-data="{columnDefs:columnDefs,rowData:filteredKeywordList}"
                            :showPagination="false"
                            @selected-row="onRowSelected"
                            @delete-tag="onDeleteKeywordSegment"
                        >
                        </ag-grid-table>
                    </div>

                    <component-loader v-else height="380px"></component-loader>

                </div>
            </template>
        </component-container>

        <confirm-dialog :title="$t('component_keywordConfiguration_confirmDeleteTitle')" ref="confirmDeleteKeyword" @confirm-dialog-valid="acceptDeleteKeyword()">
            <div v-html="$t('component_keywordConfiguration_confirmDeleteText')"></div>
        </confirm-dialog>
    </div>
</template>


<script>

import YoodaIcon from "@/components/yooda-components/YoodaIcon";
import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import SmallErrorMessage from "@/components/yooda-components/SmallErrorMessage";
import InputWithAddButton from "@/components/yooda-components/form/InputWithAddButton";
import ImportCsvKeyword from "@/components/yooda-components/project/ImportCsvKeyword";

import Vue from "vue"


import apiCalls from '@/api/axios/configuration/project.js'

export default {

    props: {
        withCreateKeyword: {type: Boolean, default: true},
        tooltipText: {type: String, default: 'component_keywordConfiguration_informationTooltip_text'}
    },
    data() {
        return {
            componentLoaded: false,
            searchKeyword: '',
            selectedFilterSegment: -1,
            selectedAddSegment: 0,
            selected: [],
        }
    },
    components: {
        YoodaIcon,
        AgGridTable,
        SmallErrorMessage,
        InputWithAddButton,
        ImportCsvKeyword
    },
    computed: {
        filteredKeywordList(){
            let filteredList = []

            if (this.searchKeyword === '') {
                filteredList = this.projectKeywords.data
            } else {
                filteredList = []
                this.projectKeywords.data.forEach((row) => {
                    if (row.keyword.includes((this.searchKeyword? this.searchKeyword.toLowerCase() : ''))) {
                        filteredList.push(row)
                    }
                })
            }

            let filteredSegmentList = []
            if (this.selectedFilterSegment === -1) {
                filteredSegmentList = filteredList
            } else {
                if (this.selectedFilterSegment === -2) {
                    filteredList.forEach((row) => {
                        if (!row.marketSegments.tags) {
                            filteredSegmentList.push(row)
                        }
                    })
                } else {
                    filteredList.forEach((row) => {
                        if (row.marketSegments.tags) {
                            row.marketSegments.tags.forEach((segment) => {
                                if (segment.id === this.selectedFilterSegment) {
                                    filteredSegmentList.push(row)
                                }
                            })
                        }
                    })
                }
            }

            return filteredSegmentList
        },

        getFilterSegments() {
            let marketSegment = []
            marketSegment.push({name: this.$t('component_keywordConfiguration_filterSegmentAll'), id: -1})
            marketSegment.push({name: this.$t('component_keywordConfiguration_filterSegmentNone'), id: -2})

            if (this.projectSegments.data) {
                this.projectSegments.data.forEach((segment) => {
                    marketSegment.push({name: segment.name, id: segment.id})
                })
            }
            return marketSegment
        },

        disableActionButton() {
            if(this.selected.length === 0) {
                return true
            }
            return false
        },

        columnDefs() {
            return [
                {
                    headerName: this.$t('component_keywordConfiguration_table_header_keyword') + " (" + this.filteredKeywordList.length + ")",
                    field: 'keyword',
                    checkboxSelection: true,
                    headerCheckboxSelection: true
                },
                {
                    headerName: this.$t('component_keywordConfiguration_table_header_segment'),
                    field: 'marketSegments',
                    cellRendererFramework: 'CellRendererTags',
                    sortable: false
                },
            ]
        },
    },

    watch: {

        selectedAddSegment() {
            this.addKeywordToSegment(this.selectedAddSegment)
        }
    },

    methods: {

        onPasteKeyword() {
            setTimeout(() => {
                this.addKeywords()
            }, 200);
        },

        addKeywords(inputKeywords) {
            let newKeywords = []

            this.explodeStringToArray(inputKeywords).forEach((keyword) => {
                if(keyword.trim() !== ''){
                    newKeywords.push({string: this.cleanKeywordInput(keyword)})
                }
            })
            if (newKeywords.length > 0) {

                this.$vs.loading()

                let promise = apiCalls.createKeyword(this.$route.params.projectId, {keywords: newKeywords})
                promise.then((response) => {
                    if (response) {
                        this.setDataComponent( {
                            stateName:'projectKeywords',
                            promise: apiCalls.getKeywords(this.$route.params.projectId),
                            dataFormatter: this.projectKeywordsData
                        })
                        this.setDataComponent({
                            stateName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        }).then(()=>{
                            this.setDataComponent({
                                stateName:'projectsSummary',
                                promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                            })
                        })
                        this.selected = []

                        localStorage.setItem('settingKeywordDone','true')

                        this.analyticsIdentify()
                    }
                    this.$vs.loading.close()
                }).catch((error) => {
                    this.displayForbidenError(error.response)
                    this.$vs.loading.close()
                })
            }
        },

        resetSearchFilter() {
            this.searchKeyword = ''
        },

        onRowSelected(value) {
            this.selected = value
        },

        onDeleteKeywordSegment(value) {
            if (value.data.keywordId && value.tag.id) {
                let promise = apiCalls.removeKeywordGroupsFromKeyword(value.data.keywordId, {keywordGroups: [{id: value.tag.id}]})
                promise.then((response) => {
                    if (response) {
                        this.setDataComponent( {
                            stateName:'projectKeywords',
                            promise: apiCalls.getKeywords(this.$route.params.projectId),
                            dataFormatter: this.projectKeywordsData
                        })
                        this.setDataComponent({
                            stateName:'projectSegments',
                            promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                        })

                        this.setDataComponent({
                            stateName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        }).then(()=>{
                            this.setDataComponent({
                                stateName:'projectsSummary',
                                promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                            })
                        })

                        this.analyticsIdentify()
                    }
                }).catch((error) => {
                    Vue.error('delete keyword segment', error)
                })

            }
        },

        addKeywordToSegment(segmentId) {

            if (segmentId > 0) {
                let keywords = []
                this.selected.forEach((keyword) => {
                    keywords.push({id: keyword.keywordId})
                })

                if (keywords.length > 0) {
                    this.$vs.loading()
                    let promise = apiCalls.addKeywordsToKeywordGroup(segmentId, {keywords: keywords})
                    promise.then((response) => {
                        if (response) {
                            this.selectedAddSegment = 0

                            this.setDataComponent( {
                                stateName:'projectKeywords',
                                promise: apiCalls.getKeywords(this.$route.params.projectId),
                                dataFormatter: this.projectKeywordsData
                            })
                            this.setDataComponent({
                                stateName:'projectSegments',
                                promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                            })

                            this.setDataComponent({
                                stateName:'projectInfo',
                                promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                                dataFormatter: this.projectData
                            }).then(()=>{
                                this.setDataComponent({
                                    stateName:'projectsSummary',
                                    promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                                })
                            })


                            this.analyticsIdentify()
                        }
                        this.$vs.loading.close()
                    }).catch((error) => {
                        this.displayForbidenError(error.response)
                        this.$vs.loading.close()
                    })
                }
            }
        },

        confirmDeleteKeyword() {

            if(this.project.data.keywords.length >1){
                if(this.project.data.keywords.length === this.selected.length){
                    this.selected.pop()
                }
                this.$refs.confirmDeleteKeyword.displayDialog()
            }
            else{
                this.$vs.notify({
                    time:6000,
                    title:this.$t('component_keywordConfiguration_alertNinKeywordDeleteTitle'),
                    text:this.$t('component_keywordConfiguration_alertNinKeywordDeleteText'),
                    position:'top-center',
                    color: 'danger'
                })
            }

        },

        alertNoKeywordSelected(){
            this.$vs.notify({
                time:6000,
                title:this.$t('component_keywordConfiguration_alertNoKeywordSelectedTitle'),
                text:this.$t('component_keywordConfiguration_alertNoKeywordSelectedText'),
                position:'top-center'
            })
        },

        acceptDeleteKeyword() {
            let keywordsToDelete = []
            this.selected.forEach((keyword) => {
                keywordsToDelete.push({id: keyword.keywordId})
            })



            if(this.project.data.keywords.length >1){
                if (keywordsToDelete.length > 0) {
                    this.$vs.loading()
                    let promise = apiCalls.deleteKeyword(this.$route.params.projectId, {keywords: keywordsToDelete})
                    promise.then((response) => {
                        if (response) {
                            this.setDataComponent( {
                                stateName:'projectKeywords',
                                promise: apiCalls.getKeywords(this.$route.params.projectId),
                                dataFormatter: this.projectKeywordsData
                            })
                            this.getComponentData({
                                stateName:'projectSegments',
                                promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                            })
                            this.setDataComponent({
                                stateName:'projectInfo',
                                promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                                dataFormatter: this.projectData
                            }).then(()=>{
                                this.setDataComponent({
                                    stateName:'projectsSummary',
                                    promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                                })
                            })

                            this.selected = []

                            this.analyticsIdentify()
                        }
                        this.$vs.loading.close()
                    }).catch((error) => {
                        Vue.error('delete keyword', error)
                    })
                }
            }


        },

        initComponentData(){

            this.setDataComponent( {
                stateName:'projectKeywords',
                promise: apiCalls.getKeywords(this.$route.params.projectId),
                dataFormatter: this.projectKeywordsData
            }).then(()=>{
                this.setDataComponent( {
                    stateName:'projectSegments',
                    promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                }).then(()=>{
                    this.componentLoaded = true

                })
            })


        }
    },

    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

#keywordAddContainer {
    margin: 5px 0 12px 0;
    padding: 12px 12px 6px 12px;
    background-color: #edf3fb;
    border-radius: 5px;
}

#keywordsList {
    padding: 14px 14px 0 14px;
    border: 1px solid #eaeaea;
    border-radius: 5px;


    .tableContainer {
        height: 590px;
        > div {
            > div {
                height: 585px;
            }
        }
        &.with-create-keyword {
            height: 480px;
            > div {
                > div {
                    height: 475px;
                }
            }
        }
    }
}

#filter-table-container {
    >div{
        display: inline-block;
        vertical-align: middle;
        width: 210px;
        margin-right: 10px;
    }
    label{
        font-size: 13px;
        color: #111111;
        margin: 0;
    }
    .vs-con-input{
        margin-top: 2px;
        .vs-inputx{
            line-height: 18px;
            height: 40px;
            margin-left: 5px;
            border: 1px solid #dae1e7 !important;
            border-radius: 4px;
        }
        .input-span-placeholder{
            color: #3e3e3e;
            font-size: 13px;
            margin-left: 5px;
            padding-left: 30px !important;
        }
        .vs-icon{
            top: 11px;
            left: 10px;
        }
    }


}

#action-table-container{
    padding-top: 24px;
    .add-to-segment{
        background-color: $primary;
        color: white;
        .yooda-icon{
            margin-left: -10px;
            margin-right: 2px;
        }
    }
}
#action-add-to-segment-list{
    max-height: 320px;
    .v-list-item{
        min-height: 34px;
        cursor: pointer;
        &:hover{
            background-color: #f5f5f5;
        }
    }
}

</style>

<style lang="scss" scoped>
.v-menu__content{
    padding: 0 !important;
    min-width: 205px !important;
}
</style>
