<template>


    <div class="small-error-message-container" :class="(displaySegmentError)?'show':''">
        <span>{{errorMessage}}</span>
    </div>


</template>

<script>
    export default {
        data() {
            return {
                displaySegmentError: false,
                errorMessage: '',
            }
        },
        methods: {
            hideErrorMessage(){
                this.displaySegmentError = false
            },
            displayErrorMessage(message){
                this.displaySegmentError = true
                this.errorMessage = this.$t(`smallErrorMessage_${message}`);
            }

        }

    }
</script>


<style lang="scss">
.small-error-message-container{
    text-align: center;
    padding: 8px 0;
    opacity: 0;
    transition: opacity 0.5s;
    &.show{
        opacity: 1;
    }
    span{
        padding: 5px 10px;
        background-color: lighten($danger,40);
        color: $danger;
        font-size: 12px;

    }
}
</style>
