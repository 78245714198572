<template>

    <div>
        <v-dialog v-model="showVideoContainer" >
            <v-card class="v-application">
                <v-card-text class="pa-6">
                    <iframe v-if="showVideoContainer" width="800" height="450" src="https://www.youtube.com/embed/Qx97n9pff-Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </v-card-text>
            </v-card>

        </v-dialog>

        <v-dialog v-model="firstLoad" >
            <v-card class="v-application">
                <v-card-text class="pt-4 pb-8 px-12">
                    <h3 class="text-h4 mt-6 mb-10 text-center font-weight-bold">{{ $t('component_onboardingWaitingCompute_title') }}</h3>
                    <div class="text-center mb-10">
                        <div class="d-inline-block">
                            <img src="@/assets/images/onboarding_waiting_compute_kpi.gif" alt="first_project" style="width: 100%; max-width: 100px">
                        </div>
                        <div class="d-inline-block">
                            <img src="@/assets/images/onboarding_waiting_compute_race.gif" alt="first_project" style="width: 100%; max-width: 200px">
                        </div>
                    </div>
                    <p class="primary--text text-center text-subtitle-1 mb-8">{{ $t('component_onboardingWaitingCompute_compute_text') }} <strong>{{ $t('component_onboardingWaitingCompute_compute_duration') }}</strong></p>

                    <v-divider/>

                    <div style="max-width: 500px">
                        <p class="text-subtitle-1 font-weight-bold mt-8 text-center">{{ $t('component_onboardingWaitingCompute_waiting_title') }}</p>
                        <p class="text-subtitle-1">{{ $t('component_onboardingWaitingCompute_waiting_text') }}</p>
                        <ul class="mb-8" style="list-style: disc; padding-left: 40px">
                            <li>
                                <v-row>
                                    <v-col :cols="8"><span class="text-subtitle-1">{{ $t('component_onboardingWaitingCompute_text_todo_1') }}</span></v-col>
                                    <v-col :cols="4" align-self="center"><v-btn small outlined color="primary" width="125px" @click="showVideoContainer=true">{{$t('component_onboardingWaitingCompute_btn_todo_1')}}</v-btn></v-col>
                                </v-row>
                            </li>
                            <li>
                                <v-row>
                                    <v-col :cols="8"><span class="text-subtitle-1">{{ $t('component_onboardingWaitingCompute_text_todo_2') }}</span></v-col>
                                    <v-col :cols="4" align-self="center"><v-btn small outlined color="primary" width="125px" @click="firstLoad=false">{{$t('component_onboardingWaitingCompute_btn_todo_2')}}</v-btn></v-col>
                                </v-row>
                            </li>
                            <li>
                                <v-row>
                                    <v-col :cols="8"><span class="text-subtitle-1">{{ $t('component_onboardingWaitingCompute_text_todo_3') }}</span></v-col>
                                    <v-col :cols="4" align-self="center"><v-btn small outlined color="primary" width="125px" :to="{name:'segments_configuration'}">{{$t('component_onboardingWaitingCompute_btn_todo_3')}}</v-btn></v-col>
                                </v-row>
                            </li>

                        </ul>
                    </div>

                </v-card-text>

            </v-card>
        </v-dialog>
    </div>



</template>

<script>
export default {
    name: "OnboardingWaitingCompute",
    data() {
        return {
            firstLoad:false,
            showVideoContainer:false,
        }
    },
    methods:{
    },
    beforeMount() {
        if(!localStorage.getItem(`onboardingWaitingProject${this.$router.currentRoute.params.projectId}`)){
            localStorage.setItem(`onboardingWaitingProject${this.$router.currentRoute.params.projectId}`,'true')
            this.firstLoad = true
        }
    }
}
</script>
