<template>
    <div>
        <v-row>
            <v-col cols="12" class="v-application">
                <v-progress-linear
                    indeterminate
                    color="green"
                    height="6"
                ></v-progress-linear>
                <v-alert
                    text
                    dense
                    outlined
                    color="success"
                    icon="mdi-alert-circle-outline"
                    border="left"
                    class="bordered-alert bordered-alert-white"
                    style="border-top-left-radius: 0; border-top-right-radius: 0;background-color: #fff!important;"
                >
                    <div class="pl-2" v-html="$t('common_project_creation_waiting_text')"></div>
                </v-alert>
            </v-col>
        </v-row>
        <h1 class="placeholder-fixed-content">EXEMPLE</h1>
    </div>
</template>


<script>


export default {
    data(){
        return{
            reloadTimeoutId:null
        }
    },
    mounted() {
        this.reloadTimeoutId = setTimeout(()=>{
            window.location.reload()
        },180000)
    },
    beforeDestroy() {
        clearTimeout(this.reloadTimeoutId)
    }
}
</script>

<style lang="scss">
.placeholder-fixed-content{
    position: fixed;
    top: 45%;
    left: calc(50% - 350px);
    width: 0;
    text-align: center;
    z-index: 200;
    font-size: 150px;
    font-weight: 600;
    color: #f63d0f;
    opacity: 0.4;
    @media (max-width: 1280px) {
        font-size: 110px;
        left: calc(50% - 190px);
    }
    @media (max-width: 768px) {
        font-size: 90px;
        left: calc(50% - 220px);
    }
    @media (max-width: 460px) {
        font-size: 60px;
        left: calc(50% - 150px);
    }
    @media (max-width: 320px) {
        font-size: 40px;
        left: calc(50% - 90px);
    }
}
</style>
